import { Statuses } from "@/store/enums/StatusEnums";

/**
 * Set status badge css class
 * @param status
 * @returns
 */
export const statusState = (status: string): string => {
  let state = "";
  switch (status) {
    case Statuses.LOCKED:
      state = "warning";
      break;
    case Statuses.ACTIVE:
      state = "success";
      break;
    case "canceled":
    case "failed":
    case Statuses.CANCELLED:
    case Statuses.DEBTOR:
      state = "danger";
      break;
    default:
      state = "success";
  }
  return state;
};

/**
 * Get card type by first char of a card number
 * @param item
 * @returns
 */
export const detectCard = (item) => {
  const cods = [
    {
      code: "2",
      title: "МИР",
      icon: "media/svg/card-logos/mir-logo.svg",
    },
    {
      code: "3",
      title: "American express",
      icon: "media/svg/card-logos/american-express.svg",
    },
    {
      code: "4",
      title: "Visa",
      icon: "media/svg/card-logos/visa.svg",
    },
    {
      code: "5",
      title: "Mastercard",
      icon: "media/svg/card-logos/mastercard.svg",
    },
    {
      code: "6",
      title: "Maestro",
      icon: "media/svg/card-logos/mastercard.svg",
    },
  ];
  let result = cods.find((it) => it.code == item?.card_first6.toString()[0]);
  if (undefined === result) {
    result = {
      code: "4",
      title: "Тип карты не определен",
      icon: "media/svg/card-logos/credit-card-placeholder.png",
    };
  }
  return result;
};

export const isDevelopment = (): boolean => {
  return process.env.NODE_ENV === 'development';
};